import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import cn from "classnames";
import styles from "./terms.module.sass";
import queryString from "query-string";
import { useHistory } from "react-router";

const TermsAndConditions = () => {
	const { pathname } = useLocation();
	const [typeOfTab, setTypeOfTab] = useState("terms");
	const { search } = useLocation();
	const history = useHistory();

	useEffect(() => {
		window.scrollTo(0, 0);
		const urlParam = queryString.parse(search);
		if (urlParam?.section == "privacyPolicy") {
			setTypeOfTab("privacyPolicy");
		} else if (urlParam?.section == "aml") {
			setTypeOfTab("aml");
		}else if(urlParam?.section == 'refundPolicy'){
			setTypeOfTab("refundPolicy")
		}
	}, [pathname]);

	useEffect(() => {
		history.push('TermsAndConditions?section=' + typeOfTab)
	}, [typeOfTab])

	return (
		<div className={cn("section", styles.section)}>
			<div
				className={cn("container", styles.container)}
				style={{ fontSize: 18 }}
			>
				{/* <div className="content-TermsCondition">
          {" "}
          Terms and Conditions of Use of lii
        </div> */}

				<div className="content-bottom-content">
					<div className={styles.menuWrapper_container}>
						<div className={styles.menuWrapper}>
							<div
								className={
									typeOfTab == "terms" ? styles.selected : styles.nonSelected
								}
								onClick={() => setTypeOfTab("terms")}
							>
								{"Terms and condition"}
							</div>
							<div
								className={
									typeOfTab == "privacyPolicy" ? styles.selected : styles.nonSelected
								}
								onClick={() => setTypeOfTab("privacyPolicy")}
							>
								{"Privacy policy"}
							</div>
							<div
								className={
									typeOfTab == "refundPolicy" ? styles.selected : styles.nonSelected
								}
								onClick={() => setTypeOfTab("refundPolicy")}
							>
								{"Refund policy"}
							</div>
						</div>
					</div>

					{typeOfTab === "terms" ? (
						<div className="policy">
							<div className="odd text-start">
								<h4 className={'text-center'}><strong>Lii</strong> <strong>Entertainment Marketplace Terms and Conditions</strong></h4>
								<p>Last Revised 17/03/2025</p>
								<p><strong>1.</strong><strong>&nbsp;</strong><strong>Acceptance of terms:</strong></p>
								<p>Lii is owned and operated by FOMO FZCO, whose address is at Building A1, Dubai Digital Park,</p>
								<p>Dubai Silicon Oasis, Dubai, United Arab Emirates (the &ldquo;<strong>Company</strong>&rdquo;, &ldquo;<strong>We</strong>&rdquo;, &ldquo;<strong>Us</strong>&rdquo;, &ldquo;<strong>Our</strong>&rdquo;). These</p>
								<p>Terms and Conditions (&ldquo;Terms of Service&rdquo;) constitute a legally binding agreement between the</p>
								<p>Company and each end user (&ldquo;<strong>User</strong>&rdquo;, &ldquo;<strong>You</strong>&rdquo; or &ldquo;<strong>Your</strong>&rdquo;) of Lii located at www.lii.market (&ldquo;<strong>the</strong></p>
								<p><strong>Site</strong>&rdquo;) or such other URL as may be designated by the Company from time to time, as well as any</p>
								<p>mobile apps or other related services or application thereto.</p>
								<p>PLEASE READ THESE TERMS CAREFULLY BEFORE USING THE WEBSITE. THESE</p>
								<p>TERMS GOVERN YOUR USE OF THE WEBSITE, UNLESS WE HAVE EXECUTED A</p>
								<p>SEPARATE WRITTEN AGREEMENT WITH YOU FOR THAT PURPOSE. WE ARE ONLY</p>
								<p>WILLING TO MAKE THE SITE AVAILABLE TO YOU IF YOU ACCEPT ALL OF THESE</p>
								<p>TERMS. BY USING THE SITE OR ANY PART OF IT, YOU ARE CONFIRMING THAT YOU</p>
								<p>UNDERSTAND AND AGREE TO BE BOUND BY ALL OF THESE TERMS.</p>
								<p>Any changes to these Terms will be in effect as of the &ldquo;<strong>Last Revised</strong>&rdquo; date referred to at the top</p>
								<p>of this page.</p>
								<p>IF ANY PROVISION OF THESE TERMS OR ANY FUTURE CHANGES ARE</p>
								<p>UNACCEPTABLE TO YOU, DO NOT USE OR CONTINUE TO USE THE SITE. YOUR</p>
								<p>CONTINUED USE OF THE SITE FOLLOWING THE POSTING OF ANY NOTICE OF ANY</p>
								<p>CHANGE TO THIS TERMS OF SERVICE SHALL CONSTITUTE YOUR IRREVOCABLE</p>
								<p>ACCEPTANCE IN AGREEMENT TO SUCH CHANGE.</p>
								<p><strong>2.</strong><strong>&nbsp;</strong><strong>Overview of Lii</strong></p>
								<p><strong>Lii&nbsp;</strong>offers users the opportunity to purchase official phygital products of artists, celebrities, and</p>
								<p>influencers. A Phygital Product refers to a hybrid asset that seamlessly integrates both physical</p>
								<p>and digital components. In the context of the Lii, a phygital product consists of a tangible, real-</p>
								<p>world item (such as merchandise, collectibles, or artwork) that is paired with a digital certificate</p>
								<p>of authenticity. This digital component serves as proof of authenticity, ensuring that the physicalitem purchased is genuine and officially associated with the respective artist, celebrity, or</p>
								<p>influencer. The digital authentication is secured through blockchain technology, providing</p>
								<p>tamper-proof verification and traceability of ownership.</p>
								<p>We facilitate transactions on a safe harbor basis between buyers and sellers of phygital assets,</p>
								<p>but we are not a party to any agreement between buyers and sellers of the phygital assets on the</p>
								<p>Entertainment Marketplace. While the Entertainment Marketplace acts in total neutrality towards</p>
								<p>the transactions happening on the marketplace, it reserves the right (but is not required nor</p>
								<p>obligated to) to take any discretionary action to remove assets/ resolve disputes between buyers</p>
								<p>and sellers on the platform. You irrevocably undertake not to entangle the marketplace in such</p>
								<p>disputes.</p>
								<p><strong>For phygital asset Sellers</strong>: By selling a phygital asset through the Entertainment Marketplace,</p>
								<p>you hereby represent and warrant that you own all legal right, title, and interest in the intellectual</p>
								<p>property rights of any work associated with the phygital asset, whether a physical work or</p>
								<p>otherwise. Alternatively, you confirm that you are legally authorized or licensed by the</p>
								<p>intellectual property owners or holders to provide, or sell the phygital asset on the Marketplace.</p>
								<p>For clarity, the Company has no obligation or liability to you for keeping, storing, or helping you</p>
								<p>recover any phygital asset media associated with your assets.</p>
								<p><strong>For phygital asset Buyers</strong>: When you purchase a phygital asset, you own the physical asset that</p>
								<p>is associated with certain media; however, you do not own any intellectual property rights</p>
								<p>encapsulated in such phygital asset media except for the license grants expressly set forth herein.</p>
								<p><strong>3.</strong><strong>&nbsp;</strong><strong>License to the Phygital Media</strong></p>
								<p>In connection with your use of the Entertainment Marketplace, you may be able to post, upload,</p>
								<p>or submit content to be made available through the Entertainment Marketplace, including works,</p>
								<p>whether physical or digital that are tied to phygital assets you wish to sell on the Entertainment</p>
								<p>Marketplace as a seller, and any other content associated with your phygital assets (&ldquo;<strong>Your</strong></p>
								<p><strong>Content</strong>&rdquo;). You retain all rights to Your Content you post, upload, submit, or otherwise make</p>
								<p>available through the Entertainment Marketplace, except for rights expressly granted herein. By</p>
								<p>creating, uploading, posting, sending, receiving, storing, or otherwise making available any of</p>
								<p>your Content on, in or through the Site, you grant to Lii a non-exclusive, worldwide, royalty-free, perpetual license to such Content to make available, distribute, access, use, store, copy,</p>
								<p>adapt, modify, prepare derivative works of, distribute, publish, transmit, stream, download,</p>
								<p>broadcast, and otherwise distribute such Content solely for the purpose of providing and/or</p>
								<p>promoting the Site, featuring your Content within our Site, and promoting it through our</p>
								<p>marketing ecosystem. To the extent applicable and permissible by law, you hereby waive any</p>
								<p>and all claims that you may now or hereafter have in any jurisdiction to so-called &ldquo;moral rights&rdquo;</p>
								<p>or right of &ldquo;droit moral&rdquo; with respect to any of your Content. You may request to remove your</p>
								<p>User Materials in accordance with our Privacy Policy, which is hereby incorporated by</p>
								<p>reference.</p>
								<p>If you sell a phygital asset through the Entertainment Marketplace, you grant to the buyer of the</p>
								<p>phygital asset a worldwide, non-exclusive, non-transferable, royalty-free license to use, copy,</p>
								<p>and display the phygital asset media for such purchased phygital asset, solely for the following</p>
								<p>purposes: (a) for the buyer&rsquo;s own personal use; (b) serve as a proof of authenticity.</p>
								<p><strong>4. Account Registration and Communication Preferences</strong></p>
								<p>If you wish to use the Entertainment Marketplace in any way, you will need to register for an</p>
								<p>account on the Site (&ldquo;<strong>Account</strong>&rdquo;). By creating an Account, you agree to (a) provide accurate,</p>
								<p>current, and complete Account information about yourself, including a valid email address, (b)</p>
								<p>maintain and promptly update your Account information as necessary, (c) ensure the security of</p>
								<p>your magic wallet and its linked email address, acting as the sole custodian of your wallet and</p>
								<p>safeguarding its contents, and (d) immediately notify us if you discover or otherwise suspect any</p>
								<p>security breaches related to the Site or your Account. We will block all multiple accounts of the</p>
								<p>same user.</p>
								<p>You will not need a password to set up your Account; instead, you will log in using your email</p>
								<p>address and an OTP (one-time password) sent to your email. Furthermore, you agree that you</p>
								<p>will not (1) create another account if we&rsquo;ve disabled one you had unless you have our written</p>
								<p>permission first; (2) buy, sell, rent or lease access to your Account or username unless you have</p>
								<p>our written permission first; and (3) log in or try to log in to access the Site through unauthorized</p>
								<p>third-party applications or clients.Your Account is directly linked to a magic wallet that holds the digital assets tied to your</p>
								<p>phygital goods. At no point does the Marketplace hold custody of the digital content linked to</p>
								<p>your phygital assets. Additionally, users may request their seed phrase at any time, and such</p>
								<p>requests will be sent to Magic to reveal the seed phrase to the user. Please note that we do not</p>
								<p>allow the connection of any other wallets to the Marketplace.</p>
								<p>By creating an Account, you also consent to receive electronic communications from Lii (e.g.,</p>
								<p>via email or by posting notices to the Site). These communications may include notices about</p>
								<p>your Account (e.g., login information and other transactional information) and are part of your</p>
								<p>relationship with us. You agree that any notices, agreements, disclosures, or other</p>
								<p>communications that we send to you electronically will satisfy any legal communication</p>
								<p>requirements, including, but not limited to, that such communications be in writing. You should</p>
								<p>maintain copies of electronic communications from us by printing a paper copy or saving an</p>
								<p>electronic copy. We may also send you promotional communications via email, including, but</p>
								<p>not limited to, newsletters, special offers, surveys, and other news and information we think will</p>
								<p>be of interest to you, in accordance with our privacy policy. You may opt out of receiving these</p>
								<p>promotional emails at any time by following the unsubscribe instructions provided in our privacy</p>
								<p>notice.</p>
								<p><strong>5. Specific Terms for the Platform</strong></p>
								<p>Lii has developed an exclusive platform that enables users to purchase their favorite artists&apos;</p>
								<p>merchandise and ensure the ownerships authenticity by blockchain technology. Only certain</p>
								<p>creators who are invited or otherwise approved by the Company (&quot;Onboarded Creators&quot;) are</p>
								<p>allowed sell phygital assets on the platform. The Company may enter into an addendum with any</p>
								<p>Onboarded Creators (a &quot;Creator Addendum&quot;) setting forth specific terms for using the Platform;</p>
								<p>in case of conflict between a Creator Addendum and these Terms, the Creator Addendum shall</p>
								<p>take precedence for such Onboarded Creators. All users and Onboarded Creators agree that the</p>
								<p>phygital assets on the Platform are non-transferable and non-resellable outside of Lii, and they</p>
								<p>will exclusively remain within the user&apos;s account on Lii.</p>
								<p>Additionally, the Platform will facilitate the shipping of any physical product. The services of</p>
								<p>shipping, courier, and logistics are not managed by the Company but by its partners, and the</p>
								<p>terms and conditions of these third parties apply to these services; the Company bears noresponsibility in relation to the shipment services of any physical work attached to the phygital</p>
								<p>asset.</p>
								<p><strong>6. Verification and Payment</strong></p>
								<p>When you make purchases on the Lii, including phygital products, you must provide valid</p>
								<p>payment information linked to your Lii Account. By completing a purchase, you confirm that</p>
								<p>you are authorized to use the selected payment method and authorize us to charge the full</p>
								<p>purchase amount.</p>
								<p>Your order may be suspended or canceled if:</p>
								<p>&bull;&nbsp;The payment method cannot be verified, is invalid, or is otherwise not acceptable.</p>
								<p>&bull;&nbsp;We suspect fraudulent activity or a violation of these Terms.</p>
								<p>Lii may require additional information or documents to comply with applicable laws (e.g., anti-</p>
								<p>money laundering regulations). If you fail to provide requested information, we may pause or</p>
								<p>cancel transactions or restrict access to your account.</p>
								<p>Payments on the Marketplace are processed by third-party payment providers. By using<strong>&nbsp;</strong>Lii, you</p>
								<p>agree to their terms and policies. All payments must be made in fiat currency.</p>
								<p><strong>No Refunds:&nbsp;</strong>All purchases on Lii Marketplace are <strong>final</strong>. We do <strong>not</strong> provide refunds <strong>except as</strong></p>
								<p><strong>expressly stated</strong> in our <strong>Refund Policy</strong>, if applicable.</p>
								<p><strong>Taxes:</strong> You are solely responsible for any applicable taxes, duties, or assessments imposed by</p>
								<p>governmental authorities on your purchases. Lii is not responsible for any tax obligations related</p>
								<p>to your transactions.</p>
								<p><strong>7. Restrictions on Marketing and Promotional Activities and Logo Use</strong></p>
								<p>Any marketing and promotional activities, whether in digital, print, or any other form, that you</p>
								<p>may conduct in connection with your use of the Entertainment Marketplace are subject to our</p>
								<p>review and prior written approval. You should submit any such plan relating to marketing and</p>
								<p>promotional activities and materials for our review and provide any information that we</p>
								<p>reasonably request. The plan should contain all key information, including but not limited to</p>
								<p>Commented [HM1]:&nbsp;Add link to refund policydetails of the relevant phygital assets, the associated works, all planned media channels or</p>
								<p>distributions, relationships between different counterparties, etc.</p>
								<p>No mention of token-related topics or any implications relating to token issuance or listing may</p>
								<p>be included. You must not create and/or publish any marketing or promotional materials that</p>
								<p>may: (a) be deemed false and misleading; (b) breach or infringe the rights of any third parties,</p>
								<p>including intellectual property rights; (c) breach any applicable laws and regulations; (d) breach</p>
								<p>any of our guidelines or instructions that may be made available to you; and (e) bring into</p>
								<p>disrepute or damage the goodwill of Lii. We reserve the right to request changes or suspension to</p>
								<p>such plans and activities as we deem necessary and reject any plan and activity in its entirety at</p>
								<p>our absolute discretion.</p>
								<p>Subject to further guidelines that may be made available to you, we may permit you to use the</p>
								<p>Lii Marks, at our sole discretion, for the sole purposes of marketing or promoting your phygital</p>
								<p>assets on the Entertainment Marketplace. Terms of such guidelines made available to you shall</p>
								<p>be incorporated into these terms by express reference herein. You must not use Lii&rsquo;s name, logo,</p>
								<p>trade names, and any other marks (&ldquo;<strong>Lii Marks</strong>&rdquo;) without our prior written approval.</p>
								<p>You agree to indemnify us against all liabilities, costs, expenses, damages, and losses (including</p>
								<p>but not limited to any direct, indirect, or consequential losses, loss of profit, loss of reputation,</p>
								<p>loss of goodwill, and all interest, penalties, legal costs (calculated on a full indemnity basis), and</p>
								<p>all other professional costs and expenses) suffered or incurred by us arising out of or in</p>
								<p>connection with any such marketing and promotional activities that you conduct in connection</p>
								<p>with your use of the Entertainment Marketplace.</p>
								<p><strong>9. Third Party Material</strong></p>
								<p>Certain parts of the Entertainment Marketplace may display, include, or make available content,</p>
								<p>data, information, applications, or materials from third parties (&ldquo;<strong>Third Party Materials</strong>&rdquo;). By</p>
								<p>using the Entertainment Marketplace, you acknowledge and agree that the Company is not</p>
								<p>responsible for examining or evaluating the content, accuracy, completeness, availability,</p>
								<p>timeliness, validity, copyright compliance, legality, decency, quality, or any other aspect of such</p>
								<p>Third Party Materials.We do not warrant or endorse and do not assume and will not have any liability or responsibility</p>
								<p>to you or any other person for any third-party services, Third Party Materials, or any other</p>
								<p>materials, products, or services of third parties.</p>
								<p>If you have a dispute with one or more users, YOU RELEASE US FROM CLAIMS,</p>
								<p>DEMANDS, AND DAMAGES OF EVERY KIND AND NATURE, KNOWN AND</p>
								<p>UNKNOWN, ARISING OUT OF OR IN ANY WAY CONNECTED WITH SUCH DISPUTES.</p>
								<p>IN ENTERING INTO THIS RELEASE, YOU EXPRESSLY WAIVE ANY PROTECTIONS</p>
								<p>(WHETHER STATUTORY OR OTHERWISE) THAT WOULD OTHERWISE LIMIT THE</p>
								<p>COVERAGE OF THIS RELEASE TO INCLUDE THOSE CLAIMS WHICH YOU MAY</p>
								<p>KNOW OR SUSPECT TO EXIST IN YOUR FAVOR AT THE TIME OF AGREEING TO</p>
								<p>THIS RELEASE.</p>
								<p><strong>10. Limitation of Liability:</strong></p>
								<p>TO THE FULLEST EXTENT PERMITTED BY LAW, YOU AGREE THAT IN NO EVENT</p>
								<p>WILL THE COMPANY BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY LOST</p>
								<p>PROFIT OR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL</p>
								<p>OR PUNITIVE DAMAGES RELATED TO THE LOSS OF REVENUE, LOSS OF PROFIT,</p>
								<p>LOSS OF BUSINESS OR ANTICIPATED SAVING, LOSS OF USE, LOSS OF GOODWILL</p>
								<p>OR LOSS OF DATA, <strong>WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE),</strong></p>
								<p><strong>BREACH OF CONTRACT</strong>, OR OTHERWISE, EVEN IF PARTIES HAVE BEEN ADVISED</p>
								<p>OF THE POSSIBILITY OF SUCH DAMAGES; AND</p>
								<p>FOR ANY OTHER CLAIM, DEMAND, OR DAMAGES WHATSOEVER RESULTING</p>
								<p>FROM OR ARISING OUT OF OR IN CONNECTION WITH THESE TERMS OF THE</p>
								<p>DELIVERY, USE, OR PERFORMANCE OF THE SERVICE. ACCESS TO, AND USE OF,</p>
								<p>THE SERVICE, PRODUCTS OR THIRD&mdash;PARTY SITES, AND PRODUCTS ARE AT</p>
								<p>YOUR OWN DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE</p>
								<p>FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR MOBILE DEVICE OR LOSS</p>
								<p>OF DATA RESULTING THEREFROM.NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, IN NO</p>
								<p>EVENT SHALL THE MAXIMUM AGGREGATE LIABILITY OF THE COMPANY ARISING</p>
								<p>OUT OF OR IN ANY WAY RELATED TO THESE TERMS, SHALL NOT EXCEED:</p>
								<p>-&nbsp;THE TOTAL AMOUNT PAID BY YOU TO LII IN CONNECTION WITH THE</p>
								<p>MARKETPLACE IN THE LAST 6 MONTHS, OR</p>
								<p>-&nbsp;$500, WHICHEVER IS LOWER.</p>
								<p><strong>Termination</strong></p>
								<p>If you breach any of the provisions of these Terms, we reserve the right, with or without notice</p>
								<p>and in our sole discretion, to suspend, disable, terminate, or delete your account and/or your ability</p>
								<p>to access or use the Entertainment Marketplace (or any part of the foregoing) at any time and for</p>
								<p>any or no reason, and you acknowledge and agree that we shall have no liability or obligation to</p>
								<p>you in such event and that you will not be entitled to a refund of any amounts that you have already</p>
								<p>paid to us.</p>
								<p><strong>11.</strong><strong>&nbsp;</strong><strong>Governing Law:</strong></p>
								<p>All matters arising out of or relating to these Terms will be governed by and construed in</p>
								<p>accordance with the laws of the United Arab Emirates. The Courts of Dubai Integrated Economic</p>
								<p>Zone Authority shall have jurisdiction over all disputes arising under this contract.</p>
								<p><strong>12.</strong><strong>&nbsp;</strong><strong>Entire Agreement clause:</strong></p>
								<p>These Terms and our Privacy Policy constitute the entire legal agreement between you and us and</p>
								<p>will be deemed to be the final and integrated agreement between you and us and govern your</p>
								<p>access to and use of the Site and any further mobile application the Company develop, and</p>
								<p>completely replace any prior or contemporaneous agreements between you and us related to your</p>
								<p>access to or use of the Site, whether oral or written.</p>
								<p><strong>13.</strong><strong>&nbsp;</strong><strong>Contact</strong></p>
								<p>General questions or comments about the Entertainment Marketplace or these Terms should</p>
								<p>be sent either by mail to support@Lii.market</p>
							</div>
						</div>
					) : typeOfTab === "privacyPolicy" ? (
						//privacy
						<div className="policy">
							<div className="odd text-start">
								<h4 className={'text-center'}><strong>Lii Entertainment Marketplace Privacy Notice</strong></h4>
								<p>Last updated: 07/25/2023</p>
								<p>FOMO FZCO (&ldquo;LII&rdquo;, &ldquo;we&rdquo;, or &ldquo;us&rdquo;) is committed to protecting the privacy of its customers and takes its data protection responsibilities with the utmost seriousness. We have prepared this Privacy Notice (&ldquo;Notice&rdquo;) to explain to our users (&ldquo;You&rdquo;) how we collect and process personal information about them when they visit our website at <a href="https://www.lii.market/">https://www.lii.market</a> , or when they otherwise do business or make contact with us.</p>
								<p>We reserve the right to change this Privacy Notice at any point in time and for any reason. You will be updated by changes through the &ldquo;Last updated&rdquo; date on top of this Privacy Notice.</p>
								<div>1 What Personal Information does LII collect and process? Why does LII process my personal information? What are the legal bases of personal information?</div>
								<p>In order for the company to deliver its services properly and fulfil all of its desired purposes we may collect and process the following information about you:</p>
								<table>
									<tbody>
									<tr>
										<td>
											<div>
												<p>What personal information does LII collect and process?</p>
											</div>
										</td>
										<td>
											<div>
												<p>Why does LII process my personal information?</p>
											</div>
										</td>
										<td>
											<div>
												<p>Legal Basis for our use of personal information</p>
											</div>
										</td>
									</tr>
									<tr>
										<td>
											<div>
												<p>Information You Provide Us:</p>
												<div>&minus; Email address</div>
												<div>&minus; Name</div>
												<div>&minus; Date of birth</div>
												<div>&minus; Home address</div>
												<div>&minus; Phone number</div>
												<div>&minus; Device ID</div>
												<div>&minus; Transactional information</div>
												<div>&minus; Blockchain Address</div>
											</div>
										</td>
										<td>
											<div>
												<div>- Transaction services. We use your personal information to process your orders, and to communicate with you about orders and services.</div>
												<div>- Communicate with you. We use your personal information to communicate with you in relation to WAGMI Services.</div>
												<div>- Comply with Anti-Money Laundering Laws. We collect and process identity information and Sensitive Personal Data (as detailed in section I) to comply with our Know Your Customer (&ldquo;KYC&rdquo;) obligations under applicable laws and regulations, and Anti-Money Laundering laws and regulations</div>
											</div>
										</td>
										<td>
											<div>
												<div>- Performance of a contract:when we provide you with products or services or communicate with you about them. This includes when we use your personal information to take and handle orders and process payments.</div>
												<div>- Legal obligation; to comply with our legal obligations under applicable laws and regulations, and Anti-Money Laundering laws and regulations.</div>
												<div>- Your consentwhen we ask for your consent to process your personal information for a specific purpose that we communicate to you. When you consent to processing your personal information for a specified purpose, you may withdraw your consent at any time, and we will stop processing your personal information for that purpose. The withdrawal of consent does not affect the lawfulness of processing based on consent before its withdrawal.</div>
											</div>
										</td>
									</tr>
									<tr>
										<td>
											<div>
												<p>Information Collected by our Servers (or a third-party service provider):</p>
												<div>&minus; Web browser</div>
												<div>&minus; IP address</div>
												<div>&minus; Time zone</div>
												<div>&minus; Cookies that are installed on your device.</div>
												<div>&minus; Blockchain address, wallet type, and date/time stamps</div>
												<div>&minus; Transaction history;</div>
												<div>&minus; Information from other sources such as credit history information from credit bureaus;</div>
											</div>
										</td>
										<td>
											<div>
												<div>- Provide, troubleshoot, and improve LIIServices. We use your personal information to provide functionality, analyse performance, fix errors, and improve the usability and effectiveness of LIIS ervices</div>
												<div>- Fraud prevention and credit risks. We (and our service providers)process personal information to prevent and detect fraud and abuse in order to protect the security of our users, LII Services and others. We may also use scoring methods to assess and manage credit risks</div>
											</div>
										</td>
										<td>
											<div>
												<div>- Performance of a contractwhen we provide you with products or services, or communicate with you about them. This includes when we use your personal information to take and handle orders, and process payments.</div>
												<div>- Legal obligation; to comply with our legal obligations under applicable laws and regulations, and Anti-Money Laundering laws and regulations</div>
											</div>
										</td>
									</tr>
									<tr>
										<td>
											<div>
												<p>Information Collected by Technologies:</p>
												<div>- &ldquo;Log files&rdquo; track actions occurring on the Site, and collect data including your IP address, browser type, Internet service provider, referring/exit pages, and date/time stamps.</div>
												<div>- Cookies. Like many online services, we use cookies to collect information.</div>
												<div>- Pixel Tag (also referred to as clear Gifs, Web beacons, or Web bugs).</div>
											</div>
										</td>
										<td>
											<div>
												<div>&minus; Improve our services. We process personal information to improve our services and for you to have a better user experience</div>
												<div>&minus; Recommendations and personalisation. We use your personal information to recommend features and services that might be of interest to you, identify your preferences, and personalise your experience with LII Services</div>
												<div>&minus; Analyse how users interact with our Service, make improvements to our service quality, and provide users with a more personalized experience.</div>
											</div>
										</td>
										<td>
											<div>
												<div>- Your consent when we ask for your consent to process your personal information for a specific purpose that we communicate to you. When you consent to processing your personal information for a specified purpose, you may withdraw your consent at any time and we will stop processing your personal information for that purpose. The withdrawal of consent does not affect the lawfulness of processing based on consent before its withdrawal</div>
											</div>
										</td>
									</tr>
									<tr>
										<td>
											<div>
												<p>Analytics Services:</p>
												<p>Third-party analytics services: cookies, pixel tags and similar tools collect:</p>
												<div>- Number of users.</div>
												<div>- Session statistics.</div>
												<div>- Approximate geolocation.</div>
												<div>- Browser and device information.</div>
											</div>
										</td>
										<td>
											<div>
												<div>- We use the information we get from Analytics Services to improve our Service. The information generated by the Cookies or other technologies about your use of our Service (the &ldquo;Analytics Information&rdquo;) is transmitted to the Analytics Services. The Analytics Services use Analytics Information to compile reports on user activity, which we may receive on an individual or aggregate basis.</div>
											</div>
										</td>
										<td>
											<div>
												<div>- Legal obligation; to comply with our legal obligations under applicable laws and regulations, and Anti-Money Laundering laws and regulations.</div>
											</div>
										</td>
									</tr>
									</tbody>
								</table>
								<div>2 Disclosure of Your Personal Data:</div>
								<p>We attach great importance to the security of your personal data. We will not sell, share, or lease your personal information to unrelated third parties unless we are required by law to do so or have your permission. We share your Personal Information with third parties to help us use your Personal Information, as described above.</p>
								<p>We may also share personal information with the following persons:</p>
								<div>&bull; Third party service providers: We employ other companies and individuals to perform functions on our behalf. Examples include analyzing data, providing marketing assistance, processing payments, transmitting content, and assessing and managing credit risk. These third-party service providers only have access to personal information needed to perform their functions but may not use it for other purposes. Further, they must process the personal information in accordance with our contractual agreements and only as permitted by applicable data protection laws.</div>
								<div>&bull; Legal Authorities: We may be required by law or by Court to disclose certain information about you or any engagement we may have with you to relevant regulatory, law enforcement and/or other competent authorities. We will disclose information about you to legal authorities to the extent we are obliged to do so according to the law. We may also need to share your information in order to enforce or apply our legal rights or to prevent fraud.</div>
								<div>&bull; Business transfers: As we continue to develop our business, we might sell or buy other businesses or services. In such transactions, user information generally is one of the transferred business assets but remains subject to the promises made in any pre-existing Privacy Notice (unless, of course, the user consents otherwise). Also, in the event that LII or substantially all of its assets are acquired by a third party, user information will be one of the transferred assets.</div>
								<div>&bull; Protection of LII and others: We release accounts and other personal information when we believe release is appropriate to comply with the law or with our regulatory obligations; enforce or apply our Terms of Use and other agreements; or protect the rights, property or safety of LII, our users or others. This includes exchanging information with other companies and organizations for fraud protection and credit risk reduction.</div>
								<div>3 Minors:</div>
								<p>LII does not allow anyone under the age of 18 to use LII Services and does not knowingly collect personal information from children under 18.</p>
								<div>4 Third-Party Websites.</div>
								<p>Our Service may contain links to third-party websites. When you click on a link to any other website or location, you will leave our Service and go to another site, and another entity may collect Personal Data from you. We have no control over, do not review, and cannot be responsible for these third-party websites or their content. Please be aware that the terms of this Privacy Notice do not apply to these third-party websites or their content, or to any collection of your Personal Data after you click on links to such third-party websites. We encourage you to read the privacy policies of every website you visit. Any links to third-party websites or locations are for your convenience and do not signify our endorsement of such third parties or their products, content, or websites.</p>
								<div>5 Third-Party Wallets</div>
								<p>To use our Service, you must use a third-party wallet which allows you to engage in transactions on public blockchains. Your interactions with any third-party wallet provider are governed by the applicable terms of service and privacy Notice of that third party.</p>
								<div>6 Your Rights</div>
								<div>&bull; Data Access: You can access your personal data on your account&rsquo;s user dashboard. You also have the right to access a copy of your personal information and to request information about how we process your personal information. This includes information about the purposes of the processing, the categories of personal information we process, and the recipients or categories of recipient to whom the personal information have been or will be disclosed.</div>
								<div>&bull; Data Portability. You can request a copy of your personal data by submitting an email to us at <a href="mailto:dpo@lii.market">dpo@lii.market</a> and including &ldquo;Please send me a copy of my personal data&rdquo; in the &ldquo;Subject&rdquo; line. Our DPO will verify your ability to access that email, then send you a digital export of the data we hold that is associated with your email address. We will use reasonable efforts to respond to your request within 30 days of our receipt of verification of the request. Our DPO may be limited in its ability to send certain personal data to you (e.g., the identification of your electronic wallet).</div>
								<div>&bull; Data Erasure. You may submit a request to our DPO to delete your personal data by submitting an email to us at <a href="mailto:dpo@lii.market">dpo@lii.market</a> and including &ldquo;Please delete my personal data&rdquo; in the &ldquo;Subject&rdquo; line. Our DPO will verify your ability to access that email, then delete the personal data associated with your email address. We will use reasonable efforts to respond to your request within 30 days of our receipt of verification of the request.</div>
								<div>&bull; Right to object/opt-out:You have the right to request that we restrict how we process your personal information if the processing is performed for direct marketing purposes; and the processing is performed for statistical survey purposes. You may also have the right to object/restrict to the processing if was carried out in violation of the Federal Data Protection Law. If you make a valid request to restrict the processing of your personal information, we will continue to store the information. We will not process the information in any other ways, unless we have your consent; we need the personal information to establish, exercise or defend legal claims; we need to protect the rights of another natural or legal person; or we need to process the personal information for reasons of important public interest.</div>
								<div>&bull; Data Correction. You can modify your personal data via your account&rsquo;s user dashboard. Note that since some of the data we collect is specific to you &ndash; for example, your electronic wallet address &ndash; you may not be able to modify this data without needing to create a new user profile. If you are unable to rectify inaccurate data, you can contact us at <a href="mailto:dpo@lii.market">dpo@lii.market</a></div>
								<div>&bull; Right to Object to Automated Decision Making: As a data subject, you have the right to object to decisions made solely through automated processing, including profiling, if these decisions significantly affect you. If you wish to exercise this right, please contact us using the provided contact details. However, please note that there are specific cases where the right to object may not apply:</div>
								<div>- Processing Necessary for Contractual Obligations: You may not object to the automated processing of your personal data if it is necessary for the performance of a contract between you and us.</div>
								<div>- Processing Authorized by Law: You may not object to the automated processing of your personal data if it is required or authorized by applicable laws, regulations, or legal obligations.</div>
								<div>- Processing Based on Expressed Consent: You may not object to the automated processing of your personal data if you have provided explicit consent for such processing.</div>
								<div>&bull; Your Communications Preferences. You can choose whether you wish to receive marketing communications from us. If you receive marketing communications from us and would like to opt out, you can do so by following the directions in that communication. You can also make choices about your receipt of marketing communications by signing into your account and viewing and managing your communication permissions in your account&rsquo;s user dashboard, where you can update contact information, manage your contact preferences, opt out of email subscriptions, and choose whether to share your contact information with LII and our partners. Alternatively, you can request that we withdraw your consent to use your personal data by submitting an email to us at dpo@lii.market and including &ldquo;Please withdraw my consent for marketing communications&rdquo; in the &ldquo;Subject&rdquo; line. Our DPO will verify your ability to access that email, then update our systems to remove your email address from the system we use to send marketing communications. We will use reasonable efforts to respond to your request within 30 days of our receipt of verification of the request. Please note that these choices do not apply to mandatory communications that are part of the services, or to surveys or other informational communications that have their own unsubscribe method.</div>
								<p>IF YOU ARE A USER IN THE EUROPEAN ECONOMIC AREA OR UNITED KINGDOM, YOU HAVE CERTAIN RIGHTS UNDER THE RESPECTIVE EUROPEAN AND UK GENERAL DATA PROTECTION REGULATIONS (&ldquo;GDPR&rdquo;).These include the right to (i) request access and obtain a copy of your personal data; (ii) request rectification or erasure; (iii) object to or restrict the processing of your personal data; and (iv) request portability of your personal data. Additionally, if we have collected and processed your personal data with your consent, you have the right to withdraw your consent at any time.</p>
								<p>IF YOU ARE A CALIFORNIA RESIDENT, YOU HAVE CERTAIN RIGHTS UNDER THE CALIFORNIA CONSUMER PRIVACY ACT (&ldquo;CCPA&rdquo;). These include the right to (i) request access to, details regarding, and a copy of the personal information we have collected about you and/or shared with third parties; (ii) request deletion of the personal information that we have collected about you; and (iii) the right to opt-out of sale of your personal information. As the terms are defined under the CCPA, we do not &ldquo;sell&rdquo; your &ldquo;personal information.&rdquo;</p>
								<div>➢ If you wish to exercise your rights under the GDPR, CCPA, or other applicable data protection or privacy laws, please contact us at <a href="mailto:dpo@lii.market">dpo@lii.market</a> . We will use reasonable efforts to respond to your request within 30 days of our receipt of the request.</div>
								<div>➢ We may ask you to verify your identity or ask for more information about your request. We will consider and act upon any above request in accordance with applicable law. We will not discriminate against you for exercising any of these rights.</div>
								<div>➢ Notwithstanding the above, PLEASE NOT that we cannot edit or delete any information that is stored on a blockchain, for example the blockchain, as we do not have custody or control over any blockchains. The information stored on the blockchain may include purchases, sales, and transfers related to your blockchain address and NFTs held at that address.</div>
								<div>7 Where we Store and Process Personal Data; International Transfers</div>
								<p>Personal data collected by LII may be stored and processed in the United States or in any other country where LII or its affiliates, subsidiaries or service providers maintain facilities. The storage location(s) are chosen in order to operate efficiently, to improve performance, and to create redundancies in order to protect the data in the event of an outage or other problem. We take steps to ensure that the data we collect is processed according to the provisions of this Notice and the requirements of applicable law wherever the data is located.</p>
								<p>We transfer personal data from the UAE to other countries, some of which may not have an adequate level of data protection. When we engage in such transfers, we use a variety of legal mechanisms, including contracts, to help ensure your rights and protections travel with your data.</p>
								<div>8 Data Retention:</div>
								<p>We keep your personal information to enable your continued use of LII Services, for as long as it is required in order to fulfil the relevant purposes described in this Privacy Notice, and as may be required by law such as for tax and accounting purposes, compliance with Anti-Money Laundering laws, or as otherwise communicated to you.</p>
								<div>9 Advertising:</div>
								<p>In order for us to provide you with the best user experience, we may share your personal information with our marketing partners for the purposes of targeting, modelling, and/or analytics as well as marketing and advertising. You have a right to object at any time to processing of your personal information for direct marketing purposes.</p>
								<div>10 Contact Information:</div>
								<p>Our data protection officer can be contacted at dpo@lii.market, and will work to address any questions or issues that you have with respect to the collection and processing of your personal information.</p>

							</div>
						</div>

					) : typeOfTab === 'refund' ? (
						<div className={'policy'}>

						</div>
					):(
						//aml polcy
						<div className="policy">
							<div className="odd text-start">
								<h4 className={'text-center'}><strong>Refund Policy</strong></h4>
								<p>Welcome to Lii, where we offer authentic celebrity merchandise and collectibles. We are
								 committed to ensuring transparency and compliance with applicable laws. Please review our
								 refund policy carefully:</p>
								<p>1. Returns and Refunds</p>
								<p>All purchases made on Lii are final, and we do not offer refunds except in the following case:</p>
								<p>&bull;&nbsp;The item was damaged before arrival.
								If the above applies, customers must notify us within 7 days of receiving the item by contacting
								support@lii.market with proof of the issue (e.g., photos, order details). Refunds, replacements, or
								repairs will be processed in accordance with applicable law. Lii reserves the sole discretion to
								determine whether a refund, replacement or repair will be provided based on the evidence submitted by the customer.</p>
								<p>2. Delivery Issues</p>
								<p>If a courier service is unable to deliver the item due to incorrect information provided by the customer:</p>
								<p>&bull;&nbsp;We will attempt to contact the customer via their registered email.</p>
								<p>&bull;&nbsp;If no response is received within 5 business days, the item will be returned to our inventory.</p>
								<p>&bull;&nbsp;In such cases, the return shipping fee will be deducted, and the remaining amount may be refunded, in accordance with applicable laws.</p>
								<p>3. Product Representation &amp; Warranties</p>
								<p>&bull;&nbsp;Lii warrants that all items sold on the Marketplace are authentic, of satisfactory quality, and fit for the intended purpose as described.</p>
								<p>&bull;&nbsp;Customers acknowledge that the physical product may not be identical to its digital representation, as colors, textures, and appearances may vary slightly due to screen display differences.</p>
								<p>&bull;&nbsp;The product received will be substantially similar to the listing on the Marketplace, but minor variations may occur.</p>
								<p>For any disputes or concerns, customers may contact us at support@lii.market.</p>
							</div>
						</div>
					)}
				</div>
			</div>
		</div >
	);
};

export default TermsAndConditions;
